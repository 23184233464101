<template>
    <PageLayout>
        <template v-slot:default>
            <div class="d-flex justify-space-between mb-6">
                <div class="d-flex align-center">
                    <h1>{{ $t('accounting.report.movement.title') }}</h1>
                </div>
            </div>
            <TabsNav strict :items="breadcrumbs()[1].items" />
            <v-row>
                <v-col>
                    <v-card>
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <v-menu
                                        v-model="fromMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <TextInput
                                                v-model="from"
                                                :label="$t('accounting.report.movement.from')"
                                                v-bind="attrs"
                                                v-on="on"
                                                id="fromt"
                                            ></TextInput>
                                        </template>
                                        <v-date-picker
                                            v-model="from"
                                            @input="fromMenu = false"

                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col>
                                    <v-menu
                                        v-model="toMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <TextInput
                                                v-model="to"
                                                :label="$t('accounting.report.movement.to')"
                                                v-bind="attrs"
                                                v-on="on"
                                                id="fromt"
                                            ></TextInput>
                                        </template>
                                        <v-date-picker
                                            v-model="to"
                                            @input="toMenu = false"

                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <Btn @click="init">{{ $t('accounting.report.get-report') }}</Btn>
                                </v-col>
                            </v-row>
                            <v-row v-if="loading">
                                <v-col>
                                    <v-progress-linear indeterminate :active="loading" class="mx-2"></v-progress-linear>
                                </v-col>
                            </v-row>
                            <v-row v-else>
                                <v-col>
                                    <v-alert
                                        border="top"
                                        colored-border
                                        color="green accent-4"
                                        elevation="2"
                                    >
                                        {{ $t('accounting.report.income_expense.columns.income') }}: {{ income }}
                                    </v-alert>
                                    <v-alert
                                        border="top"
                                        colored-border
                                        color="red accent-2"
                                        elevation="2"
                                    >
                                        {{ $t('accounting.report.income_expense.columns.expense') }}: {{ expense }}
                                    </v-alert>
                                    <v-alert
                                        border="top"
                                        colored-border
                                        color="blue accent-4"
                                        elevation="2"
                                    >
                                        {{ $t('accounting.report.income_expense.columns.saldo') }}: {{saldo}}
                                    </v-alert>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </template>
        <template v-slot:side>
            <Side />
        </template>
    </PageLayout>
</template>
<script>
import PageLayout from "@/components/Leentech/PageLayout.vue";
import WarehouseHeader from "@/components/Warehouse/WarehouseHeader.vue";
import Side from "@/components/Warehouse/Side.vue";
import Btn from "@/components/Form/Btn.vue";
import TextInput from "@/components/Form/TextInput.vue";
import TabsNav from "@/components/Leentech/TabsNav.vue";
import NavMixin from "@/plugins/mixins/Nav";

export default {
    name: "MoneyMovementsReport",

    components: {
        TabsNav,
        TextInput,
        Btn,
        Side,
        PageLayout
    },

    mixins: [NavMixin],

    data() {
        return {
            loading: false,

            from: this.$moment().subtract(1, 'month').format('YYYY-MM-DD'),
            to: this.$moment().format('YYYY-MM-DD'),
            fromMenu: false,
            toMenu: false,

            income: 0,
            expense: 0,
            saldo: 0
        }
    },

    computed: {
        // saldo: {
        //     get() {
        //         return this.income - this.expense;
        //     }
        // }
    },

    mounted() {
        this.init();
    },

    methods: {
        init() {
            this.loading = true;
            this.fetchIncomeExpose().then((data) => {
                this.income = parseFloat(data.income);
                this.expense = parseFloat(data.expense);
                this.saldo = parseFloat(data.profit);
            }).finally(() => {
                this.loading = false;
            });
        },

        fetchIncomeExpose() {
            return new Promise((resolve, reject) => {
                this.$http.get('admin/accounting/report/income_expense', {
                    params: {
                        from: this.from,
                        to: this.to
                    }
                }).then(({body}) => {
                    resolve(body.data);
                }).catch((error) => {
                    reject(error);
                });
            });
        }
    }
}
</script>
